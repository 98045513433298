import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  name: 'business',
  data: () => ({
    payloads: {
      companyName: '',
      fullName: '',
      email: '',
      phoneNumber: ''
    }
  }),
  components: {
    School: () => import(/* webpackChunkName: "school" */ '@/components/icons/School'),
    CodeSlash: () => import(/* webpackChunkName: "code-slash" */ '@/components/icons/CodeSlash'),
    People: () => import(/* webpackChunkName: "people" */ '@/components/icons/People'),
    Person: () => import(/* webpackChunkName: "person" */ '@/components/icons/Person'),
    Carousel: () => import(/* webpackChunkName: "carousel" */ '@/components/carousel/Carousel'),
    ProductCard: () => import(/* webpackChunkName: "image" */ '@/components/card/ProductCard'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    TextField: () => import(/* webpackChunkName: "icon" */ '@/components/forms/TextField'),
    Dropdown: () => import(/* webpackChunkName: "DropDown" */ '@/components/dropdown/Dropdown'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile'])
  },
  watch: {
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('contact', ['contactUsWithCategory']),
    scrollToElement() {
      const element = document.getElementById('todo')
      element.scrollIntoView({ behavior: 'smooth' })
    },
    scrollToSection() {
      const element = document.getElementById('toGita')
      if (this.isMobile === true) {
        window.scrollTo(0, element.offsetTop - 50)
      } else {
        window.scrollTo(0, element.offsetTop - 80)
      }
    },
    toContactUs() {
      this.$router.push({ name: 'Contact Us' })
    }
  }
}
